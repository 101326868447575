<template>
  <div ref="pagina_como_ajudar" id="pagina_como_ajudar">
    <img src="/img/fundo_site_preto.png" alt="" class="fundo">
    <div id="resolucao_div1" class="titulo_page">
      <h1>Como podemos te ajudar</h1>
    </div>
    <div class="container">
      <div id="carouselExampleControls1" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-inner">
          <div class="carousel-item row active" data-bs-interval="20000">
            <div class="col-sm-12 col-md-12 col-lg-4">
              <div class="box4">
                <h4>Programas de treinamento e capacitação</h4>
                <div class="barra_h"></div>
                <p>Desenvolvemos seus colaboradores nas técnicas de resolução de problemas, melhoria contínua, inovação e demais competências do futuro.</p>
              </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4">
              <div class="box4">
                <h4>Planejamento e Desdobramento Estratégico</h4>
                <div class="barra_h"></div>
                <p>Facilitação de eventos de Planejamento Estratégico com líderes para co-criação dos pilares que apoiarão as metas de curto, médio e longo prazo, envolvendo colaboradores no desdobramento dessas metas.</p>
              </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4">
              <div class="box4">
                <h4>Governança, Gestão &amp; Gerenciamento da Rotina</h4>
                <div class="barra_h"></div>
                <p>Foco na eliminação de burocracias e gargalos, alinhando o fluxo de gestão ao que realmente importa para entregar a estratégia e operações, sem desperdício de tempo.</p>
              </div>
            </div>
          </div>
          <div class="carousel-item row" data-bs-interval="20000">
            <div class="col-sm-12 col-md-12 col-lg-4">
              <div class="box4">
                <h4>Desenvolver e Acelerar Inovações Centradas no Cliente</h4>
                <div class="barra_h"></div>
                <p>Atraves do pensamento Ágil trazemos o cliente para o centro das inovações, mapeando personas, suas necessidades e suas dificuldades para que encontremos a solução mais eficaz, assertiva e inovadora possível.</p>
              </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4">
              <div class="box4">
                <h4>Programas de Produtividade e Escritório de Projetos</h4>
                <div class="barra_h"></div>
                <p>Criamos um ambiente de co-criação focado na redução de custos e aumento de receita, utilizando Lean e Six Sigma para identificar causas raizes e garantir ganhos sustentáveis.</p>
              </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4">
              <div class="box4">
                <h4>Diagnóstico de Performance Comercial</h4>
                <div class="barra_h"></div>
                <p>Quer impulsionar suas vendas? Através de método e estratégia, te ajudamos a descobrir como vender mais e melhor, mitigando riscos e aumentando nosso padrão de compliance.</p>
              </div>
            </div>
          </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls1" data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls1" data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
      <div id="carouselExampleControls2" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-inner">
          <div class="carousel-item row active" data-bs-interval="15000">
            <div class="col-sm-12 col-md-12 col-lg-4">
              <div class="box4">
                <h4>Programas de treinamento e capacitação</h4>
                <div class="barra_h"></div>
                <p>Desenvolvemos seus colaboradores nas técnicas de resolução de problemas, melhoria contínua, inovação e demais competências do futuro.</p>
              </div>
            </div>
          </div>
          <div class="carousel-item row" data-bs-interval="15000">
            <div class="col-sm-12 col-md-12 col-lg-4">
              <div class="box4">
                <h4>Planejamento e Desdobramento Estratégico</h4>
                <div class="barra_h"></div>
                <p>Facilitação de eventos de Planejamento Estratégico com líderes para co-criação dos pilares que apoiarão as metas de curto, médio e longo prazo, envolvendo colaboradores no desdobramento dessas metas.</p>
              </div>
            </div>
          </div>
          <div class="carousel-item row" data-bs-interval="15000">
            <div class="col-sm-12 col-md-12 col-lg-4">
              <div class="box4">
                <h4>Governança, Gestão &amp; Gerenciamento da Rotina</h4>
                <div class="barra_h"></div>
                <p>Foco na eliminação de burocracias e gargalos, alinhando o fluxo de gestão ao que realmente importa para entregar a estratégia e operações, sem desperdício de tempo.</p>
              </div>
            </div>
          </div>
          <div class="carousel-item row" data-bs-interval="15000">
            <div class="col-sm-12 col-md-12 col-lg-4">
              <div class="box4">
                <h4>Desenvolver e Acelerar Inovações Centradas no Cliente</h4>
                <div class="barra_h"></div>
                <p>Atraves do pensamento Ágil trazemos o cliente para o centro das inovações, mapeando personas, suas necessidades e suas dificuldades para que encontremos a solução mais eficaz, assertiva e inovadora possível.</p>
              </div>
            </div>
          </div>
          <div class="carousel-item row" data-bs-interval="15000">
            <div class="col-sm-12 col-md-12 col-lg-4">
              <div class="box4">
                <h4>Programas de Produtividade e Escritório de Projetos</h4>
                <div class="barra_h"></div>
                <p>Criamos um ambiente de co-criação focado na redução de custos e aumento de receita, utilizando Lean e Six Sigma para identificar causas raizes e garantir ganhos sustentáveis.</p>
              </div>
            </div>
          </div>
          <div class="carousel-item row" data-bs-interval="15000">
            <div class="col-sm-12 col-md-12 col-lg-4">
              <div class="box4">
                <h4>Diagnóstico de Performance Comercial</h4>
                <div class="barra_h"></div>
                <p>Quer impulsionar suas vendas? Através de método e estratégia, te ajudamos a descobrir como vender mais e melhor, mitigando riscos e aumentando nosso padrão de compliance.</p>
              </div>
            </div>
          </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls2" data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls2" data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ComoAjudar3'
}
</script>