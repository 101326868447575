<template>
  <div id="pagina_quem_somos3">
    <img src="/img/fundo_site_preto.png" alt="" class="fundo" id="fundo_quem_somos3">
    <div id="resolucao_div1" class="titulo_page">
      <h1>Quem Somos</h1>
    </div>
    <div id="quem_somos_div2">
      <div class="row">
        <div class="col-md-12 sobre">
          <div class="container">
            <div class="row">
              <div class="col-md-12 col-lg-3">
                <div class="m-3 equipe">
                  <img src="/img/flavio.jpeg" alt="">
                  <h3 class="nome">Flávio Fraga</h3>
                  <h4 class="funcao">Inovações & Processos Comerciais</h4>
                  <p>flavio.fraga@ellevesolutions.com</p>
                  <span @click="openPopup(1)">Ler Bio <i class="bi bi-chevron-right"></i></span>
                </div>
              </div>
              <div class="col-md-12 col-lg-3">
                <div class="m-3 equipe">
                  <img src="/img/rubens.jpeg" alt="">
                  <h3 class="nome">Rubens Falasque</h3>
                  <h4 class="funcao">Estratégia & Governança</h4>
                  <p>rubens.falasque@ellevesolutions.com</p>
                  <span @click="openPopup(2)">Ler Bio <i class="bi bi-chevron-right"></i></span>
                </div>
              </div>
              <div class="col-md-12 col-lg-3">
                <div class="m-3 equipe">
                  <img src="/img/edu.jpeg" alt="">
                  <h3 class="nome">Edu Castro</h3>
                  <h4 class="funcao">Operações & Projetos</h4>
                  <p>edu.castro@ellevesolutions.com</p>
                  <span @click="openPopup(3)">Ler Bio <i class="bi bi-chevron-right"></i></span>
                </div>
              </div>
              <div class="col-md-12 col-lg-3">
                <div class="m-3 equipe">
                  <img src="/img/gabriela.jpeg" alt="">
                  <h3 class="nome">Gabriela Alves</h3>
                  <h4 class="funcao">Gestão & Lean Office</h4>
                  <p>gabriela.alves@ellevesolutions.com</p>
                  <span @click="openPopup(4)">Ler Bio <i class="bi bi-chevron-right"></i></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="isPopupOpen" class="popup">
          <div>
            <div class="popup">
              <div v-if="imageNumber == 1">
                <div class="row popup-content">
                  <div class="col-sm-4">
                    <img src="/img/flavio.jpeg" alt="">
                  </div>
                  <div class="col-sm-8">
                    <p>Flávio é um executivo com mais de 22 anos de experiência em otimização de processos e crescimento de negócios. Com atuação em empresas globais, é especialista em cadeia de suprimentos, qualidade e inovação. Co-fundador e mentor na FIA Profuturo Startup Lab, possui formação em Engenharia de Produção, MBA Executivo Internacional, Master em Design Thinking pelo MIT, e certificações em Scrum Master e Master Black Belt.</p>
                  </div>
                  <button @click="closePopup" class="close-btn">X</button>
                </div>
              </div>
              <div v-if="imageNumber == 2">
                <div class="row popup-content">
                  <div class="col-sm-4">
                    <img src="/img/rubens.jpeg" alt="">
                  </div>
                  <div class="col-sm-8">
                    <p>Atua por mais de 20 anos como Executivo de Estratégia, Governança, Produtividade e Inovação.  Engenheiro Mecânico e Mestrado pelo Instituto Tecnológico de Aeronáutica (ITA), MBA pela Business School de SP, Master Black Belt em Lean Six Sigma, PMI certificado, Scrum Master Agile. Rubens atuou nos últimos anos como membro do Board Executivo em multinacionais líderes em seus setores como Embraer, Whirpool, Maersk Line e Syngenta.</p>
                  </div>
                  <button @click="closePopup" class="close-btn">X</button>
                </div>
              </div>
              <div v-if="imageNumber == 3">
                <div class="row popup-content">
                  <div class="col-sm-4">
                    <img src="/img/edu.jpeg" alt="">
                  </div>
                  <div class="col-sm-8">
                    <p>Executivo com mais de 20 anos de experiência em empresas líderes dos segmentos agroquímico e de bens de consumo no Brasil, EUA e México. Engenheiro de formação e especialista em metodologias como Six Sigma, Lean Manufacturing e Lean Office, ele se destaca por sua capacidade de otimizar e integrar processos em áreas como Operações, Supply Chain e Comercial. Seu compromisso com a geração de valor ao cliente e a sustentabilidade a longo prazo é uma marca de sua trajetória.</p>
                  </div>
                  <button @click="closePopup" class="close-btn">X</button>
                </div>
              </div>
              <div v-if="imageNumber == 4">
                <div class="row popup-content">
                  <div class="col-sm-4">
                    <img src="/img/gabriela.jpeg" alt="">
                  </div>
                  <div class="col-sm-8">
                    <p>Master Black Belt com experiência na liderança e consultoria de projetos estratégicos multifuncionais tanto de melhoria de processos como de inovação. Gabriela se especializou na arte de simplificar e implementar sistemas de gestão e governanças em praticamente todas as funções da cadeia de valor corporativa. Seu lema é: "se não faz o cliente feliz, é desperdício de tempo e não agrega valor".</p>
                  </div>
                  <button @click="closePopup" class="close-btn">X</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'QuemSomos',
  data() {
    return {
      isPopupOpen: false,
      imageNumber: 1,
    };
  },
  methods:{
    openPopup(number) {
      this.imageNumber = number
      this.isPopupOpen = true;
    },
    closePopup() {
      this.isPopupOpen = false; // Fecha o pop-up
    },
  },
}
</script>