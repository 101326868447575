<template>
  <div id="pagina_desc_inicial">
    <img src="/img/fundo_desc_inicial.jpg" alt="" class="fundo">
    <div id="desc_inicial_div">
      <div>
        <br>
        <h3>Somente quem possui mais de 30 anos de experiência no estudo, disseminação e aplicação das metodologias de <strong>Gestão, Melhoria Continua, Inovação e Excelência Operacional,</strong> é capaz de selecionar as ferramentas ideais para resolver cada problema, nossa paixão está em te ajudar a construir um <strong>ANO PERFEITO</strong> e enraizar a cultura de <strong>MELHORIA CONTINUA!</strong></h3>
        <br>
        <h2><strong>IMAGINAR. ACONSELHAR. IMPLEMENTAR. CAPACITAR.</strong></h2>
        <br>
        <h3>Temos uma abordagem adaptável para transformar sua empresa de dentro para fora. Nosso time de especialistas está aqui para criar soluções para todos os seus maiores problemas, não importa quão complicados ou complexos sejam.</h3>
        <br>
        <h1>Descubra como transformar seus desafios em <strong>OPORTUNIDADES!</strong></h1>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PaginaResolucao'
}
</script>