<template>
  <div id="pagina_quem_somos2">
    <img src="/img/fundo_site_preto.png" alt="" class="fundo">
    <div id="quem_somos_div">
      <div class="row">
        <div class="col-md-12 col-lg-8" style="position: relative;" id="business_div1_img">
          <img src="/img/business2.jpeg" alt="" id="business2">
        </div>
        <div class="col-md-12 col-lg-4">
          <div class="sobre3">
            <div class="barra_h3"></div>
            <span>A Elleve foi criada por ex-executivos de grandes multinacionais (todos Master Black Belts, professores renomados de Lean Six Sigma, PDCA, Governança, Agile & Design Thinking) que por onde passaram, promoveram uma profunda transformação no <strong class="strong_special">sistema de gestão</strong>, na geração de <strong class="strong_special">produtividade</strong>, aceleração de <strong class="strong_special">inovações</strong> e enraizamento da cultura de <strong class="strong_special">melhoria contínua</strong>.</span>
            <!-- <span>A Elleve foi fundada por executivos que promoveram transformações sustentáveis em gestão, produtividade, inovação e melhoria contínua em grandes multinacionais. Eles são referência no Brasil em Planejamento Estratégico, Governança Corporativa, Lean, Six Sigma, Agile, Design Thinking e Gestão de Projetos.</span> -->
          </div>
        </div>
        <div class="col-md-12 col-lg-8" style="position: relative;" id="business_div2_img">
          <img src="/img/business2.jpeg" alt="" id="business2">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'QuemSomos'
}
</script>