<template>
  <div id="pagina_texto1">
    <img src="/img/fundo_site_preto.png" alt="" class="fundo">
    <div id="resolucao_div1" class="titulo_page">
      <h1>Sobre a Elleve</h1>
    </div>
    <div id="texto1">
      <div class="row">
        <div class="col-md-11 col-lg-5" id="texto1_div">
          <br>
          <div class="barra_h3"></div>
          <p id="text_attention">Uma consultoria diferente de todas... Essa sempre foi a nossa ideia.</p>
          <p>Nossa proposta de valor será ter a mesma (ou melhor) qualidade das mega Consultorias tradicionais, porém com a <strong class="strong_special">simplicidade, flexibilidade e custo de um escritório “boutique”.</strong></p>
          <p>Queremos ajudar nossos clientes a recuperar a confiança em suas operações todos os dias. Não temos a intenção de “vender método”, mas fazer com que os mais complexos problemas sejam resolvidos de forma rápida e sustentável, <strong class="strong_special">SEMPRE CENTRADOS NO CLIENTE.</strong></p>
          <!-- <p>A Equipe Elleve é apaixonada em resolver problemas, pois o sucesso nos desafios mais complexos dos nossos clientes é o que nos move. Seu problema é o nosso problema! Nosso objetivo é continuar trabalhando constantemente para criar soluções e ferramentas simples, leves e extremamente eficazes para compartilhar com nossos clientes.</p>
          <p>Aprendemos coisas novas o tempo todo e ajudamos os nossos clientes a recuperar a confiança nas suas operações todos os dias. Pensamos que não há melhor forma de utilizar os nossos conhecimentos senão traduzi-los de forma simples para atender qualquer desafio que venha a surgir.</p>
          <p>Nós da Elleve, trabalhamos em algumas das indústrias mais complexas, avançadas e desafiadoras do mundo. Somos o parceiro de solução de problemas para as mais diversas empresas do mundo. Esta visão holística e panorâmica, nos permite inovar todos os dias para tornar a vida de nossos clientes mais leve e entregar resultados muito mais rápidos (e na metade do custo!) do que as consultorias tradicionais do mercado.</p>
          <p id="text_attention">Quer elevar a sua empresa para um novo nível de gestão? Entre em contato conosco!</p> -->
        </div>
        <div class="col-sm-12 col-md-12 col-lg-7" id="business1_div">
          <img src="/img/business1.jpeg" alt="" id="business1">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PaginaResolucao'
}
</script>