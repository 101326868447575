<template>
  <div id="pagina_formulario">
    <img src="/img/fundo_site_preto.png" alt="" class="fundo">
    <div id="resolucao_div1" class="titulo_page">
      <h1>Fale Conosco</h1>
    </div>
    <div class="row">
      <div class="col-md-12 col-lg-12" id="formulario_text">
        <h3>Preencha o formulário e alguém da nossa equipe irá contatá-lo.</h3>
      </div>
      <div class="col-md-12 col-lg-12">
        <div id="formulario_campos">
          <form method="post" id="formContato">
            <div style="display: none;">
              <input type="hidden" name="_wpcf7" value="5899">
              <input type="hidden" name="_wpcf7_version" value="5.9.8">
              <input type="hidden" name="_wpcf7_locale" value="pt_BR">
              <input type="hidden" name="_wpcf7_unit_tag" value="wpcf7-f5899-p5887-o1">
              <input type="hidden" name="_wpcf7_container_post" value="5887">
              <input type="hidden" name="_wpcf7_posted_data_hash" value="">
            </div>
            <p><label> Nome*</label><br>
              <span class="wpcf7-form-control-wrap" data-name="your-name">
              <input size="40" maxlength="400" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" aria-required="true" aria-invalid="false" value="" type="text" name="your-name" id="your-name"></span>
            </p>
            <p><label> Empresa*</label><br>
            <span class="wpcf7-form-control-wrap" data-name="your-company">
            <input size="40" maxlength="400" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" aria-required="true" aria-invalid="false" value="" type="text" name="your-company" id="your-company"></span>
            </p>
            <p><label> Posição (opcional)</label><br>
              <span class="wpcf7-form-control-wrap" data-name="your-position">
              <input size="40" maxlength="400" class="wpcf7-form-control wpcf7-text" aria-invalid="false" value="" type="text" name="your-position" id="your-position"></span>
            </p>
            <p><label> Email*</label><br>
              <span class="wpcf7-form-control-wrap" data-name="your-email">
              <input size="40" maxlength="400" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" aria-required="true" aria-invalid="false" value="" type="text" name="your-email" id="your-email"></span>
            </p>
            <!-- <p><label> Localização e País*</label><br>
              <span class="wpcf7-form-control-wrap" data-name="your-country">
              <input size="40" maxlength="400" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" aria-required="true" aria-invalid="false" value="" type="text" name="your-country" id="your-country"></span>
            </p> -->
            <p><label> O seu número de telefone*</label><br>
              <span class="wpcf7-form-control-wrap" data-name="your-number">
              <input size="40" maxlength="400" class="wpcf7-form-control wpcf7-text" aria-invalid="false" value="" type="text" name="your-number" id="your-number"></span>
            </p>
            <!-- <p><label> Estou entrando em contato sobre:<br>
              <span class="wpcf7-form-control-wrap" data-name="your-recipient"><select class="wpcf7-form-control wpcf7-select wpcf7-validates-as-required" aria-required="true" aria-invalid="false" name="your-recipient"><option value="">?Escolha uma opção?</option><option value="Training Programs">Training Programs</option><option value="An URGENT Problem">An URGENT Problem</option><option value="General Inquiry">General Inquiry</option><option value="Digital Credentials Request">Digital Credentials Request</option><option value="The Dorian Awards">The Dorian Awards</option></select></span></label>
            </p> -->
            <p><label> A sua mensagem*</label><br>
              <span class="wpcf7-form-control-wrap" data-name="your-message">
              <textarea cols="40" rows="10" maxlength="2000" class="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required" aria-required="true" aria-invalid="false" name="your-message" id="your-message"></textarea></span>
            </p>
            <p style="text-align: center;">
              <input type="button" class="btn btn-success" value="Enviar" id="enviar_mensagem" onclick="enviarForm()"><span class="wpcf7-spinner"></span>
            </p>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormularioContato'
}
</script>