<template>
  <div ref="pagina_nossos_clientes" id="pagina_nossos_clientes">
    <img src="/img/fundo_site_preto.png" alt="" class="fundo">
    <div id="resolucao_div1" class="titulo_page">
      <h1>Nossos Clientes</h1>
    </div>
    <div id="nossos_clientes_div">
      <div class="row">
        <div class="img_clientes_div col-6 col-sm-6 col-md-6 col-lg-3">
          <img src="/img/clientes/agrosol.png" alt="">
        </div>
        <div class="img_clientes_div col-6 col-sm-6 col-md-6 col-lg-3">
          <img src="/img/clientes/alfa.png" alt="">
        </div>
        <div class="img_clientes_div col-6 col-sm-6 col-md-6 col-lg-3">
          <img src="/img/clientes/avon.png" class="marca_grande" alt="">
        </div>
        <div class="img_clientes_div col-6 col-sm-6 col-md-6 col-lg-3">
          <img src="/img/clientes/celestica.png" alt="">
        </div>
        <div class="img_clientes_div col-6 col-sm-6 col-md-6 col-lg-3">
          <img src="/img/clientes/coopercitrus.png.jpg" alt="">
        </div>
        <div class="img_clientes_div col-6 col-sm-6 col-md-6 col-lg-3">
          <img src="/img/clientes/costabeber.png" alt="">
        </div>
        <div class="img_clientes_div col-6 col-sm-6 col-md-6 col-lg-3">
          <img src="/img/clientes/dipagro.png.jpg" alt="">
        </div>
        <div class="img_clientes_div col-6 col-sm-6 col-md-6 col-lg-3">
          <img src="/img/clientes/embraer.png.jpg" alt="">
        </div>
        <div class="img_clientes_div col-6 col-sm-6 col-md-6 col-lg-3">
          <img src="/img/clientes/ge.png.jpg" class="marca_grande" alt="">
        </div>
        <div class="img_clientes_div col-6 col-sm-6 col-md-6 col-lg-3">
          <img src="/img/clientes/juparana.png.jpg" alt="">
        </div>
        <div class="img_clientes_div col-6 col-sm-6 col-md-6 col-lg-3">
          <img src="/img/clientes/maersk.png" alt="">
        </div>
        <div class="img_clientes_div col-6 col-sm-6 col-md-6 col-lg-3">
          <img src="/img/clientes/sinagro.png.jpg" alt="">
        </div>
        <div class="img_clientes_div col-6 col-sm-6 col-md-6 col-lg-3">
          <img src="/img/clientes/syngenta.png" alt="">
        </div>
        <div class="img_clientes_div col-6 col-sm-6 col-md-6 col-lg-3">
          <img src="/img/clientes/whirlpool.png.jpg" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NossosClientes'
}
</script>