<template>
  <div id="nav">
    <div class="row">
      <div class="col-sm-2 col-md-2 col-lg-2 col-xl-2">
        <a href="/"><img :src="(modeloSite == 'kit-colors-preto')?'/img/logos/elleveBranco.png':'/img/logos/400PngdpiLogoCropped.png'" id="logo"/></a>
      </div>
      <div class="col-sm-10 col-md-10 col-lg-10 col-xl-10">
        <div id="rotas_div">
          <a :class="{ active: activeSection === 'pagina_inicial' }"         id="menu_pagina_inicial"         class="menu_buttons" @click="scroolTo('pagina_inicial')">INÍCIO</a>
          <a :class="{ active: activeSection === 'pagina_texto1' }"          id="menu_pagina_texto1"          class="menu_buttons" @click="scroolTo('pagina_texto1')">SOBRE A ELLEVE</a>
          <a :class="{ active: activeSection === 'pagina_como_ajudar' }"     id="menu_pagina_como_ajudar"     class="menu_buttons" @click="scroolTo('pagina_como_ajudar')">COMO PODEMOS TE AJUDAR</a>
          <a :class="{ active: activeSection === 'pagina_nossos_clientes' }" id="menu_pagina_nossos_clientes" class="menu_buttons" @click="scroolTo('pagina_nossos_clientes')">NOSSOS CLIENTES</a>
          <a :class="{ active: activeSection === 'pagina_quem_somos3' }"     id="menu_pagina_quem_somos3"     class="menu_buttons" @click="scroolTo('pagina_quem_somos3')">QUEM SOMOS</a>
          <a :class="{ active: activeSection === 'pagina_formulario' }"      id="menu_pagina_formulario"      class="menu_buttons" @click="scroolTo('pagina_formulario')">FALE CONOSCO</a>
        </div>
        <!-- <nav :class="['sidebar', { 'is-visible': isNavVisible }]" id="nav_menu">
          <i class="icons bi bi-x" id="close_nav" title="Fechar" @click="toggleMenu()"></i>
          <br>
          <ul class="list-group list-group-flush">
            <li class="list-group-item" @click="scroolTo('inicio_div'), toggleMenu()">PÃ¡gina Inicial</li>
            <li class="list-group-item" @click="scroolTo('projetos_div'), toggleMenu()">Projetos</li>
            <li class="list-group-item" @click="scroolTo('skills_div'), toggleMenu()">Skills</li>
            <li class="list-group-item" @click="scroolTo('contato_div'), toggleMenu()">Contato</li>
          </ul>
        </nav> -->
        <div id="menu_hamburguer">
          <i class="bi bi-list" @click="toggleMenu()"></i>
        </div>
      </div>
      <a href="https://wa.me/+5511941516246?text=Ol%C3%A1%2C%20venho%20pelo%20site%20e%20gostaria%20de%20saber%20mais%20sobre%20a%20Elleve." target="blank">
        <img src="../public/img/whatsapp_logo.png" alt="" id="whatsapp_logo">
      </a>
    </div>
    <nav :class="['sidebar', { 'is-visible': isNavVisible }]" id="nav_menu">
      <i class="icons bi bi-x" id="close_nav" title="Fechar" @click="toggleMenu()"></i>
      <br>
      <ul class="list-group list-group-flush">
        <li class="list-group-item" @click="scroolTo('pagina_inicial'), toggleMenu()">INÍCIO</li>
        <li class="list-group-item" @click="scroolTo('pagina_texto1'), toggleMenu()">SOBRE A ELLEVE</li>
        <li class="list-group-item" @click="scroolTo('pagina_como_ajudar'), toggleMenu()">COMO PODEMOS TE AJUDAR</li>
        <li class="list-group-item" @click="scroolTo('pagina_nossos_clientes'), toggleMenu()">NOSSOS CLIENTES</li>
        <li class="list-group-item" @click="scroolTo('pagina_quem_somos3'), toggleMenu()">QUEM SOMOS</li>
        <li class="list-group-item" @click="scroolTo('pagina_formulario'), toggleMenu()">FALE CONOSCO</li>
      </ul>
    </nav>
  </div>
  <PaginaInicial/>
  <DescInicial/>
  <PaginaResolucao/>
  <Texto1/>
  <!-- <QuemSomos/> -->
  <QuemSomos2/>
  <Diferenciais/>
  <ComoAjudar/>
  <!-- <ComoAjudar2/> -->
  <NossosClientes/>
  <QuemSomos3/>
  <FormularioContato/>
  <div id="footer">
    <div class="row">
      <div class="col-md-4">
        <img src="/img/logos/400PngdpiLogoCropped.png" alt="">
      </div>
      <div class="col-md-4">
        <p>© ELLEVE | TODOS OS DIREITOS RESERVADOS.</p>
      </div>
      <div class="col-md-4">
        <p>DESENVOLVIDO POR <a href="https://myportfoliogeovane.netlify.app/" target="_blank">Geovane Barbosa</a></p>
      </div>
    </div>
  </div>
</template>
<script>
import PaginaInicial from './components/PaginaInicial.vue'
import DescInicial from './components/DescInicial.vue'
import PaginaResolucao from './components/PaginaResolucao.vue'
import Texto1 from './components/Texto1.vue'
import ComoAjudar from './components/ComoAjudar.vue'
// import ComoAjudar2 from './components/ComoAjudar2.vue'
import Diferenciais from './components/Diferenciais.vue'
// import QuemSomos from './components/QuemSomos.vue'
import QuemSomos2 from './components/QuemSomos2.vue'
import QuemSomos3 from './components/QuemSomos3.vue'
import NossosClientes from './components/NossosClientes.vue'
import FormularioContato from './components/FormularioContato.vue'

export default {
  name: 'App',
  components: {
    PaginaInicial,
    DescInicial,
    PaginaResolucao,
    Texto1,
    ComoAjudar,
    // ComoAjudar2,
    Diferenciais,
    // QuemSomos,
    QuemSomos2,
    QuemSomos3,
    NossosClientes,
    FormularioContato,
  },
  data() {
    return {
      isPopupOpen: false,
      isNavVisible: false,
      imageSrc: '',
      activeSection: '',
      modeloSite: document.getElementById('app').classList[0],
    };
  },
  mounted:function(){
    const options = {
      root: null,
      threshold: 0.5, // Define a visibilidade necessária (50% da seção visível)
    };
    
    this.observer = new IntersectionObserver(this.handleIntersection, options);
    
    // Observar cada seção
    this.$refs.pagina_inicial && this.observer.observe(this.$refs.pagina_inicial);
    this.$refs.pagina_quem_somos && this.observer.observe(this.$refs.pagina_quem_somos);
    this.$refs.pagina_como_ajudar && this.observer.observe(this.$refs.pagina_como_ajudar);
    this.$refs.pagina_nossos_clientes && this.observer.observe(this.$refs.pagina_nossos_clientes);
  },
  methods:{
    scroolTo:function(id){
      const margin_nav = (id == "pagina_inicial")?0:50;
      const targetId = id;
      const targetElement = document.querySelector('#'+targetId);

      const offset = -50;
      const elementPosition = targetElement.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset + offset+margin_nav;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    },
    // scroolTo(id) {
    //   document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
    // },
    handleIntersection(entries) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.activeSection = entry.target.id;
        }
      });
    },
    beforeDestroy() {
      this.observer.disconnect();
    },
    openPopup(number) {
      this.imageSrc = `../public/img/solucoes/solucao${number}.jpeg`
      this.isPopupOpen = true;
    },
    closePopup() {
      this.isPopupOpen = false; // Fecha o pop-up
    },
    toggleMenu:function() {
      this.isNavVisible = !this.isNavVisible;
    },
    menuFalse:function() {
      this.isNavVisible = false;
    },
  }
}
</script>