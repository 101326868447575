<template>
  <div id="pagina_diferenciais">
    <img src="/img/fundo_site_preto.png" alt="" class="fundo" id="">
    <div id="resolucao_div1" class="titulo_page">
      <h1>Nossos Diferenciais</h1>
    </div>
    <div class="row" id="img_diferenciais">
      <div class="col-md-12">
        <img src="/img/diferenciais.png" alt="" id="diferenciais_img">
      </div>
    </div>
    <!-- <img src="/img/diferenciais2.png" alt="" class="fundo" id="diferenciais_img2"> -->
  </div>
</template>

<script>
export default {
  name: 'ComoAjudar3'
}
</script>