<template>
  <div id="pagina_resolucao">
    <img src="/img/fundo_site_preto.png" alt="" class="fundo">
    <div id="resolucao_div1" class="titulo_page">
      <h1>Por que precisamos de melhoria contínua</h1>
    </div>
    <div id="resolucao_div2">
      <div class="row">
        <div class="col-col-12 col-md-12 col-lg-3" id="licao_div_relative">
          <div id="licao_div">
            <img src="/img/licao.png" alt="">
          </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-9 flex-box">
          <ul class="d-inline caixas">
            <li class="list-group-item border-gradient">
              <div class="row" style="width: 100%;">
                <div class="caixa_titulo flex-box titulo_span_div">
                  <span>Desperdícios</span>
                </div>
                <div class="caixa_barra flex-box">
                  <div class="barra"></div>
                </div>
                <div class="caixa_desc flex-box">
                  <span>Você sabia que 70% das atividades que realizamos no dia a dia são vistas como desperdício aos olhos do cliente?</span>
                </div>
              </div>
            </li>
            <li class="list-group-item border-gradient">  
              <div class="row">
                <div class="caixa_titulo flex-box titulo_span_div">
                  <span>Dilema do tempo gasto x investido</span>
                </div>
                <div class="caixa_barra flex-box">
                  <div class="barra"></div>
                </div>
                <div class="caixa_desc flex-box">
                  <span>Você sabia que líderes tendem a gastar 40% do seu tempo com burocracias e sistemas de gestão desalinhados? Boa parte deste tempo geralmente é gasto em rituais de gestão improdutivos, longos e sem propósito definido</span>
                </div>
              </div>
            </li>
            <li class="list-group-item border-gradient">
              <div class="row">
                <div class="caixa_titulo flex-box titulo_span_div">
                  <span>Dilema da "Foto vs Filme"</span>
                </div>
                <div class="caixa_barra flex-box">
                  <div class="barra"></div>
                </div>
                <div class="caixa_desc flex-box">
                  <span>Você tira fotos da sua performance todo mês e se sente surpreendido ao descobrir que não conseguiu atingir a meta? Ou você acompanha a tendência dos seus indicadores como um filme e prevê se estão num caminho positivo ou não para se antecipar aos problemas?</span>
                </div>
              </div>
            </li>
            <li class="list-group-item border-gradient">
              <div class="row">
                <div class="caixa_titulo flex-box titulo_span_div">
                  <span>Por que a maioria dos projetos falham?</span>
                </div>
                <div class="caixa_barra flex-box">
                  <div class="barra"></div>
                </div>
                <div class="caixa_desc flex-box">
                  <span>75% dos projetos falham na metade por não iniciarem com as perguntas chave: Qual o problema que estamos tentando resolver? Qual a sua causa raiz? Qual o escopo? Qual a métrica que será usada para medir seu sucesso? Quais serão os mecanismos de sustentação dos ganhos obtidos?</span>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PaginaResolucao'
}
</script>