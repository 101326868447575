<template>
  <div ref="pagina_inicial" id="pagina_inicial">
    <img src="/img/fundo1.png" alt="" class="fundo">
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12" id="especialidade">
        <div class="textos">
          <h2>Muito mais do que uma consultoria de Melhoria Contínua e Excelência Operacional</h2>
          <br>
          <span>Encontre a solução para o seu negócio! Oferecemos a mesma qualidade e expertise dos grandes grupos de consultoria, porém com a flexibilidade, custo e simplicidade de um <strong>escritório boutique.</strong></span>
          <br>
          <br>
          <span id="frase_executivos">Nosso foco é fazer a sua empresa prosperar!</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PaginaInicial'
}
</script>